import { useState } from "react";

export default function TaskDetailsCharacteristics({ selectedData }: any) {
  const data = [
    {
      id: 1,
      param_key: "Цвет",
      param_value: "Белый",
    },
    {
      id: 2,
      param_key: "Оси",
      param_value: "L1",
    },
    {
      id: 3,
      param_key: "Подвеска",
      param_value: "ресорная",
    },
    {
      id: 3,
      param_key: "ССУ (высота седельно-сцепного устройства),мм",
      param_value: "L1",
    },
  ];

  const [dropDownShow, setDropDownShow] = useState(true);

  const dataAdditional = [
    {
      id: 1,
      param_key: "рабочий",
      param_value:
        "барабанный с пневмоприводом, выполненным по двухпроводной схеме С АБС",
    },
    {
      id: 2,
      param_key: "стояночный",
      param_value:
        "“привод от пружинных энергоаккумуляторов к тормозным механизмам задних колес",
    },
  ];

  return (
    <div className="w-full flex flex-col gap-[16px] ">
      {/* Additional Info */}
      {/* <div className="w-full flex flex-col gap-[8px]  items-center">
        <div className="w-full flex gap-[5px]  items-center">
          {dropDownShow === true ? (
            <svg
              onClick={() => setDropDownShow(!dropDownShow)}
              width={14}
              height={14}
              className="fill-gray-400 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
            </svg>
          ) : (
            <svg
              onClick={() => setDropDownShow(!dropDownShow)}
              width={14}
              height={14}
              className="fill-gray-400 rotate-180 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
            </svg>
          )}

          <p className="text-[14px] font-nunito leading-[22px] uppercase">
            Тормоза:
          </p>
        </div>
        {dropDownShow === true ? (
          <div className="w-full flex flex-col gap-[8px] px-[20px]">
            {dataAdditional?.length > 0 &&
              dataAdditional?.map((value, index) => {
                return (
                  <div className="flex justify-start">
                    <p className="text-[#57585A] text-[14px] min-w-[95px] text-start">
                      {value?.param_key}:
                    </p>
                    <p className="text-[#57585A] text-[14px] text-start ">
                      {" "}
                      {value?.param_value}
                    </p>
                  </div>
                );
              })}
          </div>
        ) : null}
      </div> */}
      <div className="w-full flex flex-col gap-[8px]">
        {selectedData?.operation?.material_operations?.length > 0 &&
          selectedData?.operation?.material_operations?.map(
            (value: any, index: number) => {
              if (value?.type_operation === "stop") {
                return (
                  <div
                    key={index}
                    className="flex gap-[10px] w-full justify-start"
                  >
                    <p className="text-[#57585A] max-w-[150px] px-[5px] text-[14px]  text-start">
                      {value?.item?.name}:
                    </p>
                    <p className="text-[#57585A] max-w-[320px] relative  text-[14px] text-start">
                      {value?.item?.description}
                    </p>
                    <p className="text-[#57585A] max-w-[20px] text-[14px] text-start ">
                      {" "}
                      {value?.quantity}
                    </p>
                  </div>
                );
              }
            }
          )}
      </div>
    </div>
  );
}
