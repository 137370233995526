export default function TaskDetailsNecessaryMaterials({ selectedData }: any) {
  const data = [
    {
      id: 1,
      param_key: "Лавровый лист",
      param_value: "3 шт",
    },
    {
      id: 2,
      param_key: "Голова",
      param_value: "10 шт",
    },
    {
      id: 3,
      param_key: "Подвеска",
      param_value: "120 шт",
    },
    {
      id: 3,
      param_key: "Рама",
      param_value: "3627 шт",
    },
    {
      id: 3,
      param_key: "Рог единорога",
      param_value: "22 шт",
    },
    {
      id: 3,
      param_key: "Голова",
      param_value: "22 шт",
    },
    {
      id: 3,
      param_key: "Подвеска",
      param_value: "1 шт",
    },
    {
      id: 3,
      param_key: "Зуб демона",
      param_value: "22 шт",
    },
  ];

  return (
    <div className="w-full flex flex-col gap-[16px] ">
      <div className="w-full flex flex-col gap-[8px]">
        {selectedData?.operation?.material_operations?.length > 0 &&
          selectedData?.operation?.material_operations?.map(
            (value: any, index: number) => {
              if (value?.type_operation === "start") {
                return (
                  <div
                    key={index}
                    className="flex gap-[10px] w-full justify-start"
                  >
                    <p className="text-[#57585A] max-w-[150px] px-[5px] text-[14px]  text-start">
                      {value?.item?.name}:
                    </p>
                    <p className="text-[#57585A] max-w-[320px] relative  text-[14px] text-start">
                      {value?.item?.description}
                    </p>
                    <p className="text-[#57585A] max-w-[20px] text-[14px] text-start ">
                      {" "}
                      {value?.quantity}
                    </p>
                  </div>
                );
              }
            }
          )}
      </div>
    </div>
  );
}
