import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TaskDetailsInstructions from "./TaskDetailsInstructions";
import TaskDetailsCharacteristics from "./TaskDetailsCharacteristics";
import TaskDetailsNecessaryMaterials from "./TaskDetailsNecessaryMaterials";
import TaskDetailsBlueprints from "./TaskDetailsBlueprints";
import { requestGetFetch, requestPost } from "../../api/request";
import { DOWNTIME, TASKS, USERS } from "../../api/url";
import React from "react";
import ModalSaveResult from "./ModalSaveResult";
import ModalStopPurpose from "./ModalStopPurpose/ModalStopPurpose";
import { formatTime, timestrToSec } from "../../other/functions";
import { useAuth } from "../../useAuth";
import { toast } from "react-toastify";
import { timestampToDateTime } from "../../Commons/function";

export default function TaskDetailsOnce() {
  const { accessToken, refreshToken }: any = useAuth();
  const navigate = useNavigate();

  /**
   * 4 Основные параметры которые получаем с адресной стороки
   */
  const { taskId } = useParams();
  const { userId }: any = useParams();
  const { controlPointId } = useParams();
  const { channelWork } = useParams();
  const { typeRoute } = useParams();

  //Состояние загрузчик
  const [loader, setLoader] = useState(false);

  //Список данных добавляются в data
  const [data, setData] = useState<any[]>([]);

  // Выбранная дата
  const [selectedData, setSelectedData] = useState<any>();
  const [pauseTime, setPauseTimer] = useState(false);

  const [activeMenu, setActiveMenu] = useState("сharacteristics");

  const [hourTime, setHourTime] = useState<number>(0);
  const [secondTime, setSecondTime] = useState<number>(0);
  const [minuteTime, setMinuteTime] = useState<number>(0);

  const [timerStart, timerStop] = useState<boolean>(false);

  const selectedRoomRefSecond: any = useRef(0);
  const selectedRoomRefHour: any = useRef(0);
  const selectedRoomRefMinute: any = useRef(0);
  const intervalIDRef = React.useRef<any>(null);

  //Modal form Сохранить текущий результат
  const [modalSaveResultShow, setModalSaveResultShow] = useState(false);

  //Модальное окно Причина остановки
  const [modalStopPurpose, setModalPurpose] = useState(false);

  const [user, setUser] = useState<any>();

  const [sumMaxTime, setSumMaxTime] = useState<any>();
  const [sumMinTime, setSumMinTime] = useState<any>();

  const [downTimeDate, setDownTimeDate] = useState<any>(null);

  const [continueTimerByStart, setContinueTimerByStart] = useState(false);

  /**
   * Основная функция отвечает за получени данных о production flow
   */
  const loadData = async () => {
    setLoader(true);

    const response = await requestGetFetch(
      TASKS + `${taskId}/`,
      {
        // user_id: userId,
        control_point_id: controlPointId,
      },
      accessToken
    );
    if (response?.data?.length > 0) {
      getUser();
      setData(response?.data);
      setSelectedData(response?.data[0]);

      if (response?.data[0]?.execution_time != null) {
        const result = timestampToDateTime(response?.data[0]?.execution_time);
        let splitTime = result.split(":");

        if (splitTime.length == 2) {
          splitTime.unshift("00");
        }

        if (splitTime.length > 0) {
          selectedRoomRefHour.current = parseInt(splitTime[0]);
          selectedRoomRefMinute.current = parseInt(splitTime[1]);
          selectedRoomRefSecond.current = parseInt(splitTime[2]);

          setHourTime(parseInt(splitTime[0]));
          setMinuteTime(parseInt(splitTime[1]));
          setSecondTime(parseInt(splitTime[2]));
        }
        timerStop(true);
        setPauseTimer(true);
      }

      if (response?.data[0].downtime?.length > 0) {
        setDownTimeDate(response?.data[0].downtime[0]);
      }

      if (response?.data[0]?.operation?.material_operations?.length > 0) {
        let _sumMinTime = "00:00:00";
        let _sumMaxTime = "00:00:00";
        for (
          let i = 0;
          i < response?.data[0]?.operation?.material_operations?.length;
          i++
        ) {
          _sumMinTime = formatTime(
            timestrToSec(_sumMinTime) +
              timestrToSec(
                response?.data[0]?.operation?.material_operations[i]?.min_time
              )
          );

          _sumMaxTime = formatTime(
            timestrToSec(_sumMaxTime) +
              timestrToSec(
                response?.data[0]?.operation?.material_operations[i]?.max_time
              )
          );
        }
        setSumMaxTime(_sumMaxTime);
        setSumMinTime(_sumMinTime);
      }

      if (typeRoute === "start") {
        if (response?.data[0].downtime?.length == 0) {
          startTimer();
        } else {
          setContinueTimerByStart(true);
        }
      }
    } else {
      setData([]);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (continueTimerByStart == true) {
      continueTimer();
    }
  }, [continueTimerByStart]);

  const getUser = async () => {
    setLoader(true);
    const response = await requestGetFetch(
      USERS + userId + "/",
      {},
      accessToken
    );
    if (response?.data?.length > 0) {
      setUser(response?.data[0]);
    }

    //Проверка токена
    if (response?.error?.length > 0) {
      if (
        response?.error[0] == "detail: Given token not valid for any token type"
      ) {
        refreshToken();
      }
    }

    setLoader(false);
  };

  /**
   * Эффект случает на изменение таск ид
   */
  useEffect(() => {
    loadData();
  }, [taskId, accessToken]);

  /**
   * Эффект случает на изменение выбранного записья
   */
  useEffect(() => {
    if (typeof selectedData != "undefined") {
      if (selectedData?.current_time != null) {
        let splited = selectedData?.current_time.split(":");
        if (splited?.length > 0) {
          let parsedHour = parseInt(splited[0]);
          let parsedMinutes = parseInt(splited[1]);
          let parsedSecond = parseInt(splited[2]);
          selectedRoomRefSecond.current = parsedSecond;
          selectedRoomRefHour.current = parsedHour;
          selectedRoomRefMinute.current = parsedMinutes;
          setHourTime(parsedHour);
          setMinuteTime(parsedMinutes);
          setSecondTime(parsedSecond);
        }
      }
    }
  }, [selectedData]);

  const requestForStartTimer = async () => {
    setLoader(true);
    const response = await requestPost(
      TASKS + taskId + "/start-task/",
      {
        user_id: parseInt(userId),
      },
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == "detail: Given token not valid for any token type"
      ) {
        refreshToken();
      }
    }
    setLoader(false);
  };

  /**
   * Функция старт таймер
   */
  const startTimer = () => {
    requestForStartTimer();
    timerStop(false);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;

    timerStop(true);
    if (typeof secondTime != "undefined") {
      intervalIDRef.current = setInterval(() => {
        if (selectedRoomRefSecond.current >= 60) {
          selectedRoomRefSecond.current = 0;
          setSecondTime(1);

          const _timeUpMinute: number = selectedRoomRefMinute?.current + 1;
          selectedRoomRefMinute.current = _timeUpMinute;
          setMinuteTime(_timeUpMinute);
        }

        if (selectedRoomRefMinute.current >= 60) {
          selectedRoomRefMinute.current = 0;
          setMinuteTime(1);

          const _timeUpHour: number = selectedRoomRefHour?.current + 1;
          selectedRoomRefHour.current = _timeUpHour;
          setHourTime(_timeUpHour);
        } else {
          const _selectedRoomRefSecond = selectedRoomRefSecond.current;
          const _timeUp: number = _selectedRoomRefSecond + 1;

          selectedRoomRefSecond.current = _timeUp;
          setSecondTime(_timeUp);
        }
      }, 1000);
    }
  };

  /**
   * Функция отвечает за обновление production flow сумму деталей и времени
   */
  const saveData = async () => {
    setLoader(true);
    let sum: any = 0;
    for (
      let i = 0;
      i < selectedData?.operation?.material_operations?.length;
      i++
    ) {
      sum =
        parseInt(sum) +
        parseInt(selectedData?.operation?.material_operations[i].quantity);
    }

    const response = await requestPost(
      TASKS + `${taskId}/end-task/`,
      {
        user_id: parseInt(userId),
      },
      {},
      accessToken
    );

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == "detail: Given token not valid for any token type"
      ) {
        refreshToken();
      }
    }
    setLoader(false);
  };

  /**
   * Остановка таймера
   */
  const cancelTimer = async () => {
    timerStop(false);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
    await saveData();
    await navigate(-1);
  };

  /**
   * Пауза таймера
   */
  const pauseTimer = () => {
    setModalPurpose(true);
  };

  const pauseTimeAfterModalDone = () => {
    setPauseTimer(true);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
    // updateData();
  };

  const updateDownTime = async () => {
    if (downTimeDate != null && typeof downTimeDate != "undefined") {
      const response = await requestPost(
        DOWNTIME + `${downTimeDate?.id}/end/`,
        {
          task_id: selectedData?.id,
          user_id: parseInt(userId),
        },
        {},
        accessToken
      );

      if (response?.error?.length > 0) {
        toast.warning(response?.error[0]);
        //Проверка токена
        if (
          response?.error[0] ==
          "detail: Given token not valid for any token type"
        ) {
          refreshToken();
        }

        return false;
      }
    }

    return true;
  };

  /**
   * Продолжить таймер
   */
  const continueTimer = async () => {
    const statusTimer = await updateDownTime();
    //Если статус задачи закрытый то просто закрыть
    if (statusTimer == false) {
      return;
    }

    setPauseTimer(false);

    selectedRoomRefSecond.current = secondTime;
    selectedRoomRefHour.current = hourTime;
    if (typeof secondTime != "undefined") {
      intervalIDRef.current = setInterval(() => {
        setPauseTimer(false);
        if (selectedRoomRefSecond.current >= 60) {
          selectedRoomRefSecond.current = 0;
          setSecondTime(1);

          const _timeUpMinute: number = selectedRoomRefMinute?.current + 1;
          selectedRoomRefMinute.current = _timeUpMinute;
          setMinuteTime(_timeUpMinute);
        }

        if (selectedRoomRefMinute.current >= 60) {
          selectedRoomRefMinute.current = 0;
          setMinuteTime(1);

          const _timeUpHour: number = selectedRoomRefHour?.current + 1;
          selectedRoomRefHour.current = _timeUpHour;
          setHourTime(_timeUpHour);
        } else {
          const _selectedRoomRefSecond = selectedRoomRefSecond.current;
          const _timeUp: number = _selectedRoomRefSecond + 1;

          selectedRoomRefSecond.current = _timeUp;
          setSecondTime(_timeUp);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    document.title = "ПРОметрика";
  }, []);

  return (
    <div className="w-full flex flex-col h-full mt-[80px] ">
      {modalSaveResultShow === true ? (
        <ModalSaveResult
          selectedData={selectedData}
          modalSaveResultShow={modalSaveResultShow}
          setModalSaveResultShow={setModalSaveResultShow}
          userId={userId}
        />
      ) : null}

      {modalStopPurpose === true ? (
        <ModalStopPurpose
          selectedData={selectedData}
          modalStopPurpose={modalStopPurpose}
          setModalPurpose={setModalPurpose}
          downTimeDate={downTimeDate}
          setDownTimeDate={setDownTimeDate}
          pauseTimeAfterModalDone={pauseTimeAfterModalDone}
          userId={userId}
        />
      ) : null}
      <div className="w-full flex justify-between   gap-[10px]  text-[20px] font-nunito font-medium bg-white h-full">
        <div className="flex flex-col px-[52px]">
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="flex py-[18px] gap-[10px] cursor-pointer"
          >
            <svg
              width={18}
              height={18}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
            </svg>
            <div className="font-nunito text-[14px] font-medium">
              Назад к списку
            </div>
          </div>

          <div className="relative rounded-[8px] flex gap-[20px] ">
            <div className="w-full ">
              <div className="w-full flex flex-col gap-[24px]">
                <div className="w-full flex flex-col gap-[5px] justify-start items-start w-[646px]">
                  <h3 className="text-[28px] font-medium font-nunito  text-[#252628] ">
                    {selectedData?.operation?.name}
                  </h3>
                  <p className="font-nunito text-[16px] text-start">
                    {selectedData?.operation?.description}
                  </p>
                </div>
                <div className="w-full flex">
                  <div className="w-fit flex bg-[#F2F3F5] p-[3px]  text-[14px]">
                    {/* <div
                      onClick={() => setActiveMenu("instruction")}
                      className={
                        "px-[11px] py-[3px]  rounded-[4px] cursor-pointer opacity-60 " +
                        (activeMenu === "instruction"
                          ? "bg-white opacity-100"
                          : "")
                      }
                    >
                      Инструкция
                    </div> */}
                    <div
                      onClick={() => setActiveMenu("сharacteristics")}
                      className={
                        "px-[11px] py-[3px]  rounded-[4px] cursor-pointer opacity-60 " +
                        (activeMenu === "сharacteristics"
                          ? "bg-white opacity-100"
                          : "")
                      }
                    >
                      Характеристики
                    </div>
                    <div
                      onClick={() => setActiveMenu("necessary_materials")}
                      className={
                        "px-[11px] py-[3px]  rounded-[4px] cursor-pointer opacity-60 " +
                        (activeMenu === "necessary_materials"
                          ? "bg-white opacity-100"
                          : "")
                      }
                    >
                      Необходимые материалы
                    </div>
                    {/* <div
                      onClick={() => setActiveMenu("blueprints")}
                      className={
                        "px-[11px] py-[3px]  rounded-[4px] cursor-pointer opacity-60 " +
                        (activeMenu === "blueprints"
                          ? "bg-white opacity-100"
                          : "")
                      }
                    >
                      Чертежи
                    </div> */}
                  </div>
                </div>

                <div className="">
                  {activeMenu === "instruction" ? (
                    <TaskDetailsInstructions />
                  ) : null}

                  {activeMenu === "сharacteristics" ? (
                    <TaskDetailsCharacteristics selectedData={selectedData} />
                  ) : null}

                  {activeMenu === "necessary_materials" ? (
                    <TaskDetailsNecessaryMaterials
                      selectedData={selectedData}
                    />
                  ) : null}

                  {activeMenu === "blueprints" ? (
                    <TaskDetailsBlueprints />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Rigth side */}
        <div className="border-t-[1px] border-[#cfcfcf] flex flex-col justify-between h-[80vh] w-[400px] bg-[#ECECEC] ">
          <div className="flex flex-col gap-[30px] justify-between h-full">
            <div className="flex flex-col gap-[30px]">
              <div className="w-full flex flex-col gap-[38px] px-[31px] mt-[68px]">
                <p className="font-nunito text-[28px] font-medium">
                  Задача {selectedData?.id}
                </p>
                <div className="w-full flex flex-col gap-[8px]">
                  <div className="w-full flex flex-col gap-[5px] ">
                    <p className="font-nunito text-[16px] font-normal leading-[16px]">
                      Ответственный
                    </p>
                    <p className="font-nunito text-[16px] font-normal leading-[16px]">
                      <b>{user?.name}</b>
                    </p>
                  </div>
                  <div className="w-full flex flex-col gap-[5px]">
                    <p className="font-nunito text-[16px] font-normal leading-[16px]">
                      Минимальное плановое время выполнения: <b>{sumMinTime}</b>
                    </p>
                    <p className="font-nunito text-[16px] font-normal leading-[16px]">
                      Максимальное плановое время выполнения:{" "}
                      <b>{sumMaxTime}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col px-[31px] gap-[9px]">
                {/* <button className="w-fit px-[10px] py-[4px] rounded-[4px] text-[#696969] border border-[#cfcfcf] font-nunito font-medium">
                  Сообщить о браке
                </button> */}
                <button
                  onClick={() => setModalSaveResultShow(true)}
                  className="w-fit px-[10px] py-[4px] hover:bg-[#4E67EA] active:bg-[#3449ad] hover:text-white rounded-[4px] text-[#696969] border border-[#cfcfcf] font-nunito font-medium"
                >
                  Сохранить текущий результат
                </button>
              </div>
            </div>
            {timerStart == true ? (
              <div className="py-[13px] w-full flex flex-col justify-center items-center  border">
                <p className="font-nunito text-[14px]">
                  Время работы над задачей
                </p>
                <p className="font-nunito text-[28px]">
                  {hourTime < 10 ? "0" + hourTime : hourTime}:
                  {minuteTime < 10 ? "0" + minuteTime : minuteTime}:
                  {secondTime < 10 ? "0" + secondTime : secondTime}
                </p>
              </div>
            ) : null}
          </div>
          <div className="w-full flex flex-col px">
            {timerStart == false ? (
              <div className="py-[13px] w-full flex flex-col justify-center items-center bg-white border">
                <p className="font-nunito text-[14px]">
                  Время работы над задачей
                </p>
                <p className="font-nunito text-[28px]">
                  {hourTime < 10 ? "0" + hourTime : hourTime}:
                  {minuteTime < 10 ? "0" + minuteTime : minuteTime}:
                  {secondTime < 10 ? "0" + secondTime : secondTime}
                </p>
              </div>
            ) : null}

            {timerStart == false ? (
              <div
                onClick={() => {
                  startTimer();
                }}
                className="py-[13px] w-full bg-[#4E67EA] active:bg-[#3449ad]  hover:bg-[#3449ad] hover:text-white flex flex-col justify-center items-center cursor-pointer"
              >
                <p className="font-nunito text-[28px] text-white">Начать</p>
              </div>
            ) : (
              <>
                {pauseTime == false ? (
                  <div
                    onClick={() => {
                      pauseTimer();
                    }}
                    className="py-[13px] w-full bg-white active:bg-[#3449ad] hover:bg-[#3449ad] hover:text-white flex flex-col justify-center items-center cursor-pointer"
                  >
                    <p className="font-nunito text-[28px] ">Приостановить</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      continueTimer();
                    }}
                    className="py-[13px] w-full bg-white active:bg-[#3449ad] hover:bg-[#3449ad] hover:text-white flex flex-col justify-center items-center cursor-pointer"
                  >
                    <p className="font-nunito text-[28px] ">Возобновить</p>
                  </div>
                )}

                <div
                  onClick={() => {
                    cancelTimer();
                  }}
                  className="py-[13px] w-full bg-[#4E67EA] active:bg-[#3449ad] flex flex-col justify-center items-center cursor-pointer"
                >
                  <p className="font-nunito text-[28px] text-white">
                    Завершить
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
