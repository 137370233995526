import { useState } from "react";
import TaskDetailsInstructionsModal from "./TaskDetailsInstructionsModal";
import TaskDetailsCharacteristicsModal from "./TaskDetailsCharacteristicsModal";
import TaskDetailsNecessaryMaterialsModal from "./TaskDetailsNecessaryMaterialsModal";
import TaskDetailsBlueprintsModal from "./TaskDetailsBlueprintsModal";
import { useNavigate } from "react-router-dom";

export default function TaskDetailsModal({
  taskDetailsContainerShow,
  setTaskDetailsContainerShow,
  selectedData,
  // startTimer,
  secondTime,
  minuteTime,
  hourTime,
  timerStart,
  // cancelTimer,
  userId,
  controlPointId,
}: any) {
  const [activeMenu, setActiveMenu] = useState("сharacteristics");
  const navigate = useNavigate();
  return (
    <div className="absolute  left-0 right-0 top-0 z-50 flex h-[calc(100%-1rem)] max-h-full w-full items-center justify-center  overflow-y-auto  overflow-x-hidden text-center ">
      <div className="relative max-h-full w-full max-w-[591px] p-4">
        <div className="relative rounded-[8px] bg-white shadow flex flex-col gap-[20px] ">
          <div className="w-full flex justify-end items-end px-[12px]">
            <button
              onClick={() =>
                setTaskDetailsContainerShow(!taskDetailsContainerShow)
              }
              type="button"
              className="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
          <div className="w-full px-[32px]">
            <div className="w-full flex flex-col gap-[24px]">
              <div className="w-full flex flex-col gap-[5px] justify-start items-start">
                <h3 className="text-[18px] font-medium font-nunito  text-[#252628] ">
                  {selectedData?.operation?.name}
                </h3>
                <p className="font-nunito text-[14px] text-start">
                  {selectedData?.operation?.description}
                </p>
              </div>
              <div className="w-full flex">
                <div className="w-full flex bg-[#F2F3F5] p-[3px]  text-[14px]">
                  {/* <div
                    onClick={() => setActiveMenu("instruction")}
                    className={
                      "px-[11px] py-[3px]  rounded-[4px] cursor-pointer opacity-60 " +
                      (activeMenu === "instruction"
                        ? "bg-white opacity-100"
                        : "")
                    }
                  >
                    Инструкция
                  </div> */}
                  <div
                    onClick={() => setActiveMenu("сharacteristics")}
                    className={
                      "px-[11px] py-[3px]  rounded-[4px] cursor-pointer opacity-60 " +
                      (activeMenu === "сharacteristics"
                        ? "bg-white opacity-100"
                        : "")
                    }
                  >
                    Характеристики
                  </div>
                  <div
                    onClick={() => setActiveMenu("necessary_materials")}
                    className={
                      "px-[11px] py-[3px]  rounded-[4px] cursor-pointer opacity-60 " +
                      (activeMenu === "necessary_materials"
                        ? "bg-white opacity-100"
                        : "")
                    }
                  >
                    Необходимые материалы
                  </div>
                  {/* <div
                    onClick={() => setActiveMenu("blueprints")}
                    className={
                      "px-[11px] py-[3px]  rounded-[4px] cursor-pointer opacity-60 " +
                      (activeMenu === "blueprints"
                        ? "bg-white opacity-100"
                        : "")
                    }
                  >
                    Чертежи
                  </div> */}
                </div>
              </div>

              <div className="">
                {activeMenu === "instruction" ? (
                  <TaskDetailsInstructionsModal />
                ) : null}

                {activeMenu === "сharacteristics" ? (
                  <TaskDetailsCharacteristicsModal
                    selectedData={selectedData}
                  />
                ) : null}

                {activeMenu === "necessary_materials" ? (
                  <TaskDetailsNecessaryMaterialsModal
                    selectedData={selectedData}
                  />
                ) : null}

                {activeMenu === "blueprints" ? (
                  <TaskDetailsBlueprintsModal />
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full border-t-[1px] border-[#cfcfcf] flex ">
            <div className="py-[13px] w-1/2 flex flex-col justify-center items-center">
              <p className="font-nunito text-[14px]">
                Время работы над задачей
              </p>
              <p className="font-nunito text-[28px]">
                {hourTime < 10 ? "0" + hourTime : hourTime}:
                {minuteTime < 10 ? "0" + minuteTime : minuteTime}:
                {secondTime < 10 ? "0" + secondTime : secondTime}
              </p>
            </div>
            {timerStart == false ? (
              <div
                onClick={() =>
                  navigate(
                    `/task/${selectedData?.id}/${userId}/${controlPointId}/first/start`
                  )
                }
                className="select-none py-[13px] w-1/2 bg-[#4E67EA] active:bg-[#5870ed] hover:bg-[#768aed] flex flex-col justify-center items-center cursor-pointer"
              >
                <p className="select-none font-nunito text-[28px] text-white">
                  {selectedData?.execution_time != null
                    ? "Возобновить"
                    : "Начать"}
                </p>
              </div>
            ) : (
              <div
                // onClick={() => cancelTimer()}
                className="select-none py-[13px] w-1/2 bg-[#4E67EA] active:bg-[#5870ed] hover:bg-[#768aed]  flex flex-col justify-center items-center cursor-pointer"
              >
                <p className="select-none font-nunito text-[28px] text-white">
                  Завершить
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
