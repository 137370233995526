import { ControlPointTypes } from "./ControlPoint.types";

export default function ControlPoint(props: ControlPointTypes) {
  return (
    <a
      data-testid="control-point-container"
      href={`/login/?control_point_id=${props?.id}`}
      className="flex justify-center items-center flex-col gap-[15px] w-[230px] h-auto py-[10px] border border-gray-400 cursor-pointer "
    >
      <div
        data-testid="image-container"
        className="w-[198px] h-[154px] bg-gray-100 "
      >
        {props?.image != null ? (
          <img
            data-testid="image"
            alt={props?.description}
            className="w-[198px] h-[154px] bg-no-repeat rounded-[4px] "
            src={props?.image}
          />
        ) : null}
      </div>

      <div
        data-testid="description"
        className="w-fill  font-nunito font-bold text-[18px] leading-[19px] text-center"
      >
        {props.description}
      </div>
    </a>
  );
}
