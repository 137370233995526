import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { requestGetFetch, requestPost } from "./api/request";
import { CONTROL_POINTS, API_TOKEN_REFRESH } from "./api/url";
import { getLocaleStorage, setToLocaleStorage } from "./Commons/function";

const AuthContext = createContext("0");

export const AuthProvider = ({ children }: any) => {
  const keyLocaleStorage = "prometrica_token";
  const keyLocaleStorageRefresh = "prometrica_token_refresh";
  const [accessToken, setAccessToken] = useLocalStorage(
    "prometrica_token",
    null
  );

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    setAccessToken(data);
    // navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setAccessToken(null);
    navigate("/", { replace: true });
  };

  const refreshToken = async () => {
    const response = await requestGetFetch(CONTROL_POINTS, {}, accessToken);
    if (response?.error?.length > 0) {
      const refreshToken = await requestPost(
        API_TOKEN_REFRESH,
        {
          refresh: getLocaleStorage(keyLocaleStorageRefresh),
        },
        accessToken
      );

      //Try to refresh Token
      if (refreshToken?.data.length > 0) {
        setToLocaleStorage(keyLocaleStorage, refreshToken?.data[0]?.access);
        login(refreshToken?.data[0]?.access);
        window.location.reload();
      }

      if (refreshToken?.error.length > 0) {
        navigate("/");
      }
    }
  };

  const value: any = useMemo(
    () => ({
      accessToken,
      login,
      logout,
      refreshToken,
    }),
    [accessToken]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
