import { useState, useEffect } from "react";
import { requestGetFetch, requestPost } from "../../api/request";
import { API_TOKEN, CONTROL_POINTS } from "../../api/url";
import {
  loaderSvg,
  removeLocaleStorage,
  setToLocaleStorage,
} from "../../Commons/function";
import { toast } from "react-toastify";
import { useAuth } from "../../useAuth";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const keyLocaleStorage = "prometrica_token";
  const keyLocaleStorageRefresh = "prometrica_token_refresh";
  const { login, accessToken }: any = useAuth();
  /**
   * Запрос на получения пользователя.
   * Это временный запрос
   */
  const postAuth = async () => {
    setLoader(true);
    removeLocaleStorage(keyLocaleStorage);
    removeLocaleStorage(keyLocaleStorageRefresh);

    if (username == null || username == "" || typeof username == "undefined") {
      setLoader(false);
      toast.error("Некорректный логин");
      return;
    }

    if (password == null || password == "" || typeof password == "undefined") {
      setLoader(false);
      toast.error("Некорректный пароль");
      return;
    }

    const response = await requestPost(API_TOKEN, {
      username: username,
      password: password,
    });

    if (response?.data == null) {
      console.error("Некорректный логин или пароль");
      toast.error("Некорректный логин или пароль");
      setLoader(false);

      setUsername("");
      setPassword("");
      return;
    }

    if (response?.data?.length == 0) {
      console.error("Не возможно получить токен.");
      toast.error(
        "Не возможно получить токен. Прошу обратиться администратору"
      );
      setLoader(false);
      return;
    }

    //Установка в locale storage
    setToLocaleStorage(keyLocaleStorage, response?.data[0]?.access);
    setToLocaleStorage(keyLocaleStorageRefresh, response?.data[0]?.refresh);
    login(response?.data[0]?.access);
    navigate(`/control-points`);
    setLoader(false);
  };

  useEffect(() => {
    document.title = "ПРОметрика";
  }, []);

  useEffect(() => {
    (async () => {
      if (
        accessToken != null &&
        accessToken != "" &&
        typeof accessToken != "undefined"
      ) {
        const response = await requestGetFetch(CONTROL_POINTS, {}, accessToken);
        if (response?.error?.length == 0) {
          navigate(`/control-points`);
        } else {
        }
      }
    })();
  }, []);

  return (
    <div className="relative top-[80px] w-full h-full flex flex-col gap-[56px] m-auto justify-center items-center py-[14px] bg-[#F8F8F8] ">
      <div className="flex flex-col gap-[32px] h-full w-[360px]">
        <h1 className="font-nunito text-[24px] leading-[52px] font-semibold text-center">
          Вход
        </h1>
        <div className="flex gap-[20px] flex-col w-full justify-center items-center">
          <div className="flex flex-col gap-[10px] w-full">
            <input
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              autoComplete="off"
              className="font-nunito px-[10px] py-[5px] text-[16px]"
              type="text"
              placeholder="Имя пользователя"
            />
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              autoComplete="off"
              className="font-nunito px-[10px] py-[5px] text-[16px]"
              type="password"
              placeholder="Пароль"
            />
          </div>
          <div
            onClick={() => {
              postAuth();
            }}
            className={
              "font-nunito w-full cursor-pointer bg-[#4E67EA] text-white hover:bg-[#4E67EA] transition-all ease-linear hover:text-white rounded-[4px]  px-[12px] py-[8px] flex grow text-center justify-center items-center "
            }
          >
            {loader === false ? "Вход" : loaderSvg()}
          </div>
        </div>
      </div>
    </div>
  );
}
