import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import russiaIcon from "../../assets/images/russia.png";
import notificationIcon from "../../assets/images/notificationIcon.png";
import {
  requestGetFetch,
  requestGetFetchByOptions,
  requestPost,
} from "../../api/request";
import { SHIFT, TASKS, USERS } from "../../api/url";
import Modal from "./Modal";
import LogoIcon from "../../icons/LogoIcon";
import { useAuth } from "../../useAuth";

export default function Header() {
  const { accessToken, logout, refreshToken }: any = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showListMenu, setShowListMenu] = useState(false);

  const [fullScreen, setFullScreen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [userId, setUserId] = useState(searchParams.get("user_id") || "");
  const [controlPointId, setControlPointId] = useState(
    searchParams.get("control_point_id") || ""
  );
  const [shifId, setShiftId] = useState(searchParams.get("shift_id") || "");

  const [modal, setModal] = useState(false);

  const [users, setUsers] = useState<any>();

  //Получаем пользователя с Бек
  const getUsers = async () => {
    const response = await requestGetFetch(
      USERS + searchParams.get("user_id") + "/",
      {},
      accessToken
    );

    if (response?.data?.length > 0) {
      setUsers(response?.data[0]);

      //Закрываем смену
      if (response?.data[0]?.shift.length > 0) {
        const response_shift = await requestPost(
          SHIFT + response?.data[0]?.shift[0]?.id + "/end/",
          {},
          {},
          accessToken
        );
      }
    }
  };

  const getData = async () => {
    const response = await requestGetFetchByOptions(
      TASKS + "get-tasks/",
      {
        user_id: searchParams.get("user_id"),
        control_point_id: searchParams.get("control_point_id"),
        offset: 0,
        limit: 5,
        status: "Незавершённые",
        text: "",
      },
      {},
      accessToken
    );

    if (response?.error?.length > 0) {
      if (
        response?.error[0] == "detail: Given token not valid for any token type"
      ) {
        refreshToken();
      }
    }

    //Есть у нас незавершенные
    if (response?.data?.tasks?.length > 0) {
      setModal(true);
    }
    //Если нет у нас не завершенных задачи.
    if (response?.data?.tasks?.length == 0) {
      if (
        searchParams.get("control_point_id") != null &&
        typeof searchParams.get("control_point_id") != "undefined"
      ) {
        await getUsers();
        navigate(
          `/login/?control_point_id=${searchParams.get("control_point_id")}`
        );
      }
    }
  };

  const handleExit = () => {
    if (
      searchParams.get("user_id") == null ||
      typeof searchParams.get("user_id") == "undefined"
    ) {
      navigate(-1);
      console.log("The User Id is not found");
      return;
    }

    if (
      searchParams.get("control_point_id") == null ||
      typeof searchParams.get("control_point_id") == "undefined"
    ) {
      navigate(-1);
      console.log("The controlPointId is not found");
      return;
    }
    getData();
  };

  return (
    <>
      {modal == true ? (
        <Modal
          modal={modal}
          setModal={setModal}
          controlPointId={searchParams.get("control_point_id")}
        />
      ) : null}

      <div
        className={
          "lg:max-w-full z-30 fixed top-0 w-full h-[80px] border-b-[1px] border-gray-200 px-[48px] flex justify-between items-center bg-white " +
          (location.pathname.split("/")[1] === "task" ? "bg-white" : "")
        }
      >
        <div
          className={
            "flex gap-[10px] justify-center items-center font-nunito font-bold text-[20px] cursor-pointer " +
            (location.pathname.split("/")[1] === "task" ? "text-black" : "")
          }
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          <LogoIcon w={70} h={70} />

          <span data-testid="logoname">ПРОметрика</span>
        </div>
        <div>
          {location.pathname.split("/")[1] === "task" ? (
            <div className="flex gap-[32px] justify-center items-center">
              <div className="w-[24px] h-[24px] flex justify-center items-center ">
                {/* <img src={notificationIcon} className="bg-no-repeat" /> */}
              </div>
              <div className="flex gap-[11px] justify-center items-center">
                <button
                  onClick={() => {
                    handleExit();
                  }}
                  className="text-center text-[14px] px-[14px] h-[40px] border border-[#F0F0F0] rounded-[6px] bg-white text-black transition-all ease-linear hover:bg-blue-200 active:bg-blue-300 "
                >
                  Завершить смену
                </button>
                <div
                  onClick={() => {
                    logout();
                  }}
                  className="font-nunito bg-slate-500 text-white px-[10px] py-[5px] rounded-[5px] cursor-pointer"
                >
                  Выход
                </div>
                {fullScreen == false ? (
                  <svg
                    onClick={() => {
                      setFullScreen(true);
                      document.body.requestFullscreen();
                    }}
                    width={32}
                    height={32}
                    className="fill-black opacity-85 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z" />
                  </svg>
                ) : (
                  <svg
                    onClick={() => {
                      setFullScreen(false);
                      document.exitFullscreen();
                    }}
                    width={32}
                    height={32}
                    className="fill-black opacity-85 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M456 224H312c-13.3 0-24-10.7-24-24V56c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l40 40L442.3 5.7C446 2 450.9 0 456 0s10 2 13.7 5.7l36.7 36.7C510 46 512 50.9 512 56s-2 10-5.7 13.7L433 143l40 40c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8zm0 64c9.7 0 18.5 5.8 22.2 14.8s1.7 19.3-5.2 26.2l-40 40 73.4 73.4c3.6 3.6 5.7 8.5 5.7 13.7s-2 10-5.7 13.7l-36.7 36.7C466 510 461.1 512 456 512s-10-2-13.7-5.7L369 433l-40 40c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V312c0-13.3 10.7-24 24-24H456zm-256 0c13.3 0 24 10.7 24 24V456c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-40-40L69.7 506.3C66 510 61.1 512 56 512s-10-2-13.7-5.7L5.7 469.7C2 466 0 461.1 0 456s2-10 5.7-13.7L79 369 39 329c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8H200zM56 224c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2l40-40L5.7 69.7C2 66 0 61.1 0 56s2-10 5.7-13.7L42.3 5.7C46 2 50.9 0 56 0s10 2 13.7 5.7L143 79l40-40c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V200c0 13.3-10.7 24-24 24H56z" />
                  </svg>
                )}
              </div>
              {showListMenu === false ? (
                <svg
                  onClick={() => setShowListMenu(!showListMenu)}
                  width={25}
                  height={25}
                  className="fill-black opacity-85 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                </svg>
              ) : (
                <svg
                  onClick={() => setShowListMenu(!showListMenu)}
                  width={25}
                  height={25}
                  className="fill-black opacity-85 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              )}
            </div>
          ) : (
            <div className="flex gap-[16px] justify-center items-center">
              <div className="w-[32px] h-[32px] ">
                {/* <img src={russiaIcon} className="bg-no-repeat" /> */}
              </div>

              <div className="flex gap-[11px] justify-center items-center">
                {fullScreen == false ? (
                  <svg
                    onClick={() => {
                      setFullScreen(true);
                      document.body.requestFullscreen();
                    }}
                    width={25}
                    height={25}
                    className="fill-black opacity-85 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z" />
                  </svg>
                ) : (
                  <svg
                    onClick={() => {
                      setFullScreen(false);
                      document.exitFullscreen();
                    }}
                    width={25}
                    height={25}
                    className="fill-black opacity-85 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M456 224H312c-13.3 0-24-10.7-24-24V56c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l40 40L442.3 5.7C446 2 450.9 0 456 0s10 2 13.7 5.7l36.7 36.7C510 46 512 50.9 512 56s-2 10-5.7 13.7L433 143l40 40c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8zm0 64c9.7 0 18.5 5.8 22.2 14.8s1.7 19.3-5.2 26.2l-40 40 73.4 73.4c3.6 3.6 5.7 8.5 5.7 13.7s-2 10-5.7 13.7l-36.7 36.7C466 510 461.1 512 456 512s-10-2-13.7-5.7L369 433l-40 40c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V312c0-13.3 10.7-24 24-24H456zm-256 0c13.3 0 24 10.7 24 24V456c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-40-40L69.7 506.3C66 510 61.1 512 56 512s-10-2-13.7-5.7L5.7 469.7C2 466 0 461.1 0 456s2-10 5.7-13.7L79 369 39 329c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8H200zM56 224c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2l40-40L5.7 69.7C2 66 0 61.1 0 56s2-10 5.7-13.7L42.3 5.7C46 2 50.9 0 56 0s10 2 13.7 5.7L143 79l40-40c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V200c0 13.3-10.7 24-24 24H56z" />
                  </svg>
                )}

                <div className="font-nunito">Русский</div>
                <svg
                  width={14}
                  height={14}
                  className="fill-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
              </div>
              <div
                onClick={() => {
                  logout();
                }}
                className="font-nunito bg-slate-500 text-white px-[10px] py-[5px] rounded-[5px] cursor-pointer"
              >
                Выход
              </div>
            </div>
          )}
        </div>
        {showListMenu === true ? (
          <div className="w-[360px] h-[565px] bg-white shadow-xl absolute top-[79px] right-0">
            <div className="w-full flex flex-col justify-between h-full">
              <ul className=" mt-[90px] w-full flex flex-col justify-center items-center [&>li]:text-black [&>li]:select-none [&>li]:w-[196px] [&>li]:h-[48px] [&>li]:flex [&>li]:justify-center [&>li]:items-center [&>li]:cursor-pointer [&>li]:font-nunito [&>li]:font-semibold">
                <li className="bg-[#F8F9FE] hover:bg-[#4E67EA] hover:text-white text-[#4E67EA]">
                  Задачи
                </li>
                <li className=" hover:bg-[#4E67EA] hover:text-white">
                  Личный кабинет
                </li>
                <li className=" hover:bg-[#4E67EA] hover:text-white">
                  Обучение
                </li>
                <li className=" hover:bg-[#4E67EA] hover:text-white">
                  Поддрежка
                </li>
              </ul>
              <div className="flex gap-[16px] justify-center items-center pb-[20px]">
                <div className="w-[32px] h-[32px">
                  {/* <img src={russiaIcon} className="bg-no-repeat" /> */}
                </div>
                <div className="flex gap-[11px] justify-center items-center">
                  <div className="font-nunito text-black select-none">
                    Русский
                  </div>
                  <svg
                    width={14}
                    height={14}
                    className="fill-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
