export default function TaskDetailsInstructions() {
  const data = [
    {
      id: 1,
      step: 1,
      text: "Шаг 1",
      description: "Описание Этапа",
    },
    {
      id: 2,
      step: 2,
      text: "Шаг 2",
      description: "Описание Этапа",
    },
    {
      id: 3,
      step: 3,
      text: "Шаг 3",
      description: "Описание Этапа",
    },
    {
      id: 4,
      step: 4,
      text: "Шаг 4",
      description: "Описание Этапа",
    },
    {
      id: 5,
      step: 5,
      text: "Шаг 5",
      description: "Описание Этапа",
    },
  ];
  return (
    <div className="w-full flex flex-col gap-[10px] pb-[30px]">
      {data?.length > 0 &&
        data?.map((value, index) => {
          return (
            <div key={index} className="w-full flex flex-col">
              <div className="w-full flex gap-[16px]">
                <div className="flex flex-col relative">
                  <div className="w-[32px] h-[32px] bg-[#D5D5D5] rounded-full font-nunito text-white text-[16px] flex justify-center items-center">
                    {value?.step}
                  </div>
                  <div className="w-[2px] h-full bg-[#D5D5D5] absolute top-[24px] left-[15px]"></div>
                </div>
                <div className="w-fit flex flex-col gap-[2px] justify-start items-start">
                  <p className="text-[16px] font-nunito font-medium leading-[14px]">
                    {value?.text}
                  </p>
                  <div className="text-[16px] font-nunito text-[#888888] min-h-[47px] text-start leading-[19px]">
                    {value?.description}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
