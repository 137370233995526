import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home.";
import Login from "./components/Login/Login";
import Task from "./components/Task/Task";
import TaskDetailsOnce from "./components/Task/TaskDetailsOnce";
import WelcomeUser from "./components/WelcomeUser/WelcomeUser";
import Header from "./components/Partials/Header";
import ControlPoints from "./components/ControlPoints/ControlPoints";
import ToastProvider from "./Commons/toast.provider";
import { AuthProvider } from "./useAuth";
import { ProtectedRoute } from "./ProtectedRoute";
function App() {
  return (
    <ToastProvider>
      <BrowserRouter>
        <AuthProvider>
          <Header />
          <Routes>
            {/* Start Home page */}
            <Route index path="/" element={<Home />}></Route>
            {/* <Route path="/dashboard" element={<AuthGuard Component={<p>Dashboard</p>} />} /> */}

            <Route
              path="/control-points"
              element={
                <ProtectedRoute>
                  <ControlPoints />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="/login"
              element={
                <ProtectedRoute>
                  <Login />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/welcome-user"
              element={
                <ProtectedRoute>
                  <WelcomeUser />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/task"
              element={
                <ProtectedRoute>
                  <Task />
                </ProtectedRoute>
              }
            ></Route>
            {/* 
            <Route path="/task" element={<Task />}>
              <ProtectedRoute>
                <Route index element={<Task />} />
              </ProtectedRoute>
            </Route> */}

            <Route
              path="/task/:taskId/:userId/:controlPointId/:channelWork/:typeRoute"
              element={
                <ProtectedRoute>
                  <TaskDetailsOnce />
                </ProtectedRoute>
              }
            />
          </Routes>
          {/* <Footer number={10} /> */}
        </AuthProvider>
      </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
