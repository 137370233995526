import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestGetFetch } from "../../api/request";
import { CONTROL_POINTS } from "../../api/url";
import { loaderSvg } from "../../Commons/function";
import ControlPoint from "../Home/ControlPoint/ControlPoint";
import { useAuth } from "../../useAuth";
import { toast } from "react-toastify";
export default function ControlPoints() {
  const { accessToken, refreshToken }: any = useAuth();

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );

  const [currentPage, setCurrentPage] = useState(
    searchParams.get("page") || undefined
  );

  const getData = async () => {
    setLoader(true);
    const response = await requestGetFetch(CONTROL_POINTS, {}, accessToken);
    if (response?.data?.length > 0) {
      setData(response?.data);
      setLoader(false);
    }
    if (response?.error?.length > 0) {
      //Проверка токена
      toast.error(response?.error[0]);
      if (
        response?.error[0] == "detail: Given token not valid for any token type"
      ) {
        refreshToken();
      }
    }
  };

  useEffect(() => {
    if (searchText) {
      const delayDebounceFn = setTimeout(() => {
        setData([]);
        getData();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else {
      getData();
    }
  }, [currentPage, searchText]);

  useEffect(() => {
    document.title = "ПРОметрика";
  }, []);

  return (
    <div className="relative top-[80px] w-full flex flex-col gap-[56px] m-auto justify-center items-center py-[14px] bg-[#F8F8F8] ">
      <div className="flex flex-col gap-[32px]">
        <h1 className="font-nunito text-[24px] leading-[52px] font-semibold text-center">
          Выберите контрольную точку
        </h1>
        <div className="flex w-full justify-center items-center">
          {loader == true ? (
            loaderSvg()
          ) : (
            <div className="flex flex-wrap max-w-[900px] gap-[35px] justify-center overflow-y-auto ">
              {data?.length > 0 &&
                data.map((value, index) => {
                  return (
                    <ControlPoint
                      id={value.id}
                      description={value.description}
                      image={value.image}
                      key={index}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
