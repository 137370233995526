export const timestrToSec = (timestr: string) => {
  var parts:any = timestr.split(":");
  return (parts[0] * 3600) +
         (parts[1] * 60) +
         (+parts[2]);
}

export const pad = (num: any) =>  {
  if(num < 10) {
    return "0" + num;
  } else {
    return "" + num;
  }
}

export const formatTime = (seconds:any) => {
  return [pad(Math.floor(seconds/3600)),
          pad(Math.floor(seconds/60)%60),
          pad(seconds%60),
          ].join(":");
}