import { useEffect, useRef, useState } from "react";
import TaskDetails from "./TaskDetailsModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestGetFetchByOptions } from "../../api/request";
import { MAIN_URL, TASKS } from "../../api/url";
import { loaderSvg, timestampToDateTime } from "../../Commons/function";
import ProjectPagination from "../../Commons/pagination";
import { limitItemsOptions } from "../../other/constants";
import Select from "../../Commons/Select";
import React from "react";
import { useAuth } from "../../useAuth";

export default function Task() {
  const { accessToken, refreshToken }: any = useAuth();
  const navigate = useNavigate();
  const [taskDetailsContainerShow, setTaskDetailsContainerShow] =
    useState(false);

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const statusRef = React.useRef<any>(null);
  const userIdRef = React.useRef<any>(null);
  const controlPointIdRef = React.useRef<any>(null);

  const [controlPointId, setControlPointId] = useState(
    searchParams.get("control_point_id") || ""
  );

  const [channelWork, setChannelWork] = useState(
    searchParams.get("channel_work") || ""
  );

  const [userId, setUserId] = useState(searchParams.get("user_id") || "");

  const [searchText, setSearchText] = useState<any>(
    searchParams.get("search") || ""
  );
  const [status, setStatus] = useState<any>(
    searchParams.get("status") || "В ожидании"
  );

  const [limitFilter, setLimitFilter] = useState<any>(
    searchParams.get("limit") || 5
  );

  const searchTextRef = React.useRef<any>(null);

  const [currentPage, setCurrentPage] = useState<any>(
    searchParams.get("current_page") || 1
  );
  // const currentPageRef = React.useRef<any>(null); // every 30 second

  // const [currentIndex, setCurrentIndex] = useState(0);

  const [options, setOptions] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState<any>(
    searchParams.get("options") || []
  );
  const [parsedSelectedOptions, setParsedSelectedOptions] = useState<any>([]);

  const selectedOptionsRef = React.useRef<any>(null); // every 30 second
  const dataRef = React.useRef<any>(null); // every 30 second

  const [hourTime, setHourTime] = useState<number>(0);
  const [secondTime, setSecondTime] = useState<number>(0);
  const [minuteTime, setMinuteTime] = useState<number>(0);

  const [timerStart, timerStop] = useState<boolean>(false);

  const selectedRoomRefSecond: any = useRef(0);
  const selectedRoomRefMinute: any = useRef(0);
  const selectedRoomRefHour: any = useRef(0);

  const setFilterOptions = (e: any) => {
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const dataOptionid = optionElement.getAttribute("data-option-id");
    if (optionElementId == null) {
      return;
    }

    if (parsedSelectedOptions.length === 0) {
      const firstObject = [
        {
          option_id: dataOptionid,
          id: optionElementId,
          value: e.target.value,
        },
      ];
      setParsedSelectedOptions(firstObject);

      searchParams.set("options", JSON.stringify(firstObject));
      setSearchParams(searchParams);
    } else {
      const isExist = parsedSelectedOptions.filter(
        (item: any) => item?.id === optionElementId
      );

      if (isExist?.length == 0) {
        const newObject = {
          option_id: dataOptionid,
          id: optionElementId,
          value: e.target.value,
        };
        searchParams.set(
          "options",
          JSON.stringify([...parsedSelectedOptions, newObject])
        );
        searchParams.set("current_page", "1");
        setParsedSelectedOptions([...parsedSelectedOptions, newObject]);
        setCurrentPage(1);
        setSearchParams(searchParams);
      }
    }
  };

  const removeOptionById = (id: any) => {
    setParsedSelectedOptions((parsedSelectedOptions: any) =>
      parsedSelectedOptions.filter((data: any) => data.id !== id)
    );

    const result = parsedSelectedOptions.filter((data: any) => data.id !== id);
    if (result.length === 0) {
      searchParams.delete("options");
      setSearchParams(searchParams);
    } else {
      searchParams.set("options", JSON.stringify(result));
      setSearchParams(searchParams);
    }
  };

  const loadData = async () => {
    statusRef.current = status;
    userIdRef.current = userId;
    controlPointIdRef.current = controlPointId;
    searchTextRef.current = searchText;

    //Start Match
    const _currentPage: any = searchParams.get("current_page") || 1;
    const _selectedOptions: any = searchParams.get("options") || [];

    let _currentIndex: any = 0;
    let _intCurrentPage: any;
    selectedOptionsRef.current = [];
    try {
      _intCurrentPage = parseInt(_currentPage);
      selectedOptionsRef.current = JSON.parse(_selectedOptions);
    } catch (err) {
      //console.error(err);
    }

    if (
      _intCurrentPage != null &&
      typeof _intCurrentPage != "undefined" &&
      _intCurrentPage != ""
    ) {
      if (_intCurrentPage > 0) {
        const _limitFilter: any = (_intCurrentPage - 1) * limitFilter;
        _currentIndex = _limitFilter;
      }
    }
    // End Match

    setLoader(true);
    let response;
    if (selectedOptionsRef.current?.length === 0) {
      response = await requestGetFetchByOptions(
        TASKS + "get-tasks/",
        {
          // user_id: statusRef.current == "В ожидании" ? "" : userIdRef.current,
          control_point_id: controlPointIdRef.current,
          offset: _currentIndex,
          limit: limitFilter,
          status: statusRef.current,
          text: searchTextRef.current,
        },
        {},
        accessToken
      );
    } else {
      const newOptionsParsedInt = selectedOptionsRef.current?.map(
        (value: any) => {
          return {
            id: parseInt(value?.option_id),
            value: value?.value,
          };
        }
      );
      response = await requestGetFetchByOptions(
        TASKS + "get-tasks/",
        {
          // user_id: statusRef.current == "В ожидании" ? "" : userIdRef.current,
          control_point_id: controlPointIdRef.current,
          offset: _currentIndex,
          limit: limitFilter,
          status: statusRef.current,
          text: searchTextRef.current,
        },
        newOptionsParsedInt,
        accessToken
      );
    }

    if (response?.error?.length > 0) {
      if (
        response?.error[0] == "detail: Given token not valid for any token type"
      ) {
        refreshToken();
      }
    }

    if (response?.data?.tasks?.length > 0) {
      setData(response?.data?.tasks);
      dataRef.current = data;

      setTotalItems(response?.count);
      setOptions(response?.data?.options);

      const totalPagesCustom = Math.ceil(response?.count / limitFilter);
      setTotalPages(totalPagesCustom);
    } else {
      setData([]);
      dataRef.current = [];
    }
    setLoader(false);
  };

  const handleSearchText = (e: any) => {
    const { target } = e;
    setCurrentPage(1);
    searchParams.set("current_page", "1");
    if (target.value !== null && target.value !== "") {
      searchParams.set("search", target.value);
      searchParams.delete("page");
      setSearchText(target.value);
    } else {
      searchParams.delete("search");
      setSearchText("");
    }
    setSearchParams(searchParams);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    searchParams.set("current_page", page.toString());
    setSearchParams(searchParams);
  };

  const handleLimitSelect = (data: any) => {
    setLimitFilter(data);
    searchParams.set("limit", data);
    setSearchParams(searchParams);
  };

  const updateData = (newData: any) => {
    if (dataRef.current == null) {
      return;
    }

    if (dataRef.current?.length == 0) {
      return;
    }

    const updatedMessage = dataRef.current.map((value: any, index: any) => {
      if (
        value?.id === newData?.id &&
        value?.is_ready_to_start !== newData.is_ready_to_start
      ) {
        return { ...value, is_ready_to_start: newData.is_ready_to_start };
      }
      return value;
    });

    if (updatedMessage?.length > 0) {
      setData(updatedMessage);
    }
  };

  useEffect(() => {
    if (
      selectedOptions != "" &&
      selectedOptions != null &&
      typeof selectedOptions != "undefined"
    ) {
      setParsedSelectedOptions(JSON.parse(selectedOptions));
    }
  }, [selectedOptions]);

  useEffect(() => {
    loadData();
  }, [limitFilter, status, searchParams, accessToken]);

  useEffect(() => {
    if (typeof selectedData != "undefined") {
      if (selectedData.execution_time != null) {
        const result = timestampToDateTime(selectedData.execution_time);
        let splitTime = result.split(":");

        if (splitTime.length == 2) {
          splitTime.unshift("00");
        }

        if (splitTime.length > 0) {
          selectedRoomRefHour.current = parseInt(splitTime[0]);
          selectedRoomRefMinute.current = parseInt(splitTime[1]);
          selectedRoomRefSecond.current = parseInt(splitTime[2]);
          setHourTime(parseInt(splitTime[0]));
          setMinuteTime(parseInt(splitTime[1]));
          setSecondTime(parseInt(splitTime[2]));
        }
      }
    }
  }, [selectedData]);

  useEffect(() => {
    document.title = "ПРОметрика";

    const es = new EventSource(
      `${MAIN_URL}${TASKS}event-stream-status?control_point_id=${controlPointId}`
    );
    es.onopen = () => {
      // console.log(">>> Connection opened!")
    };
    es.onerror = (e) => console.log("ERROR!", e);
    es.onmessage = (e) => {
      const parsedData = JSON.parse(e.data);
      if (parsedData?.length == 0) {
        return;
      }
      for (let i = 0; i < parsedData.length; i++) {
        updateData(parsedData[i]);
      }
    };
    return () => es.close();
  }, []);

  return (
    <div className="w-full flex flex-col h-full  mt-[80px] ">
      {taskDetailsContainerShow === true ? (
        <TaskDetails
          taskDetailsContainerShow={taskDetailsContainerShow}
          setTaskDetailsContainerShow={setTaskDetailsContainerShow}
          selectedData={selectedData}
          // startTimer={startTimer}
          secondTime={secondTime}
          minuteTime={minuteTime}
          hourTime={hourTime}
          timerStart={timerStart}
          // cancelTimer={cancelTimer}
          userId={userId}
          controlPointId={controlPointId}
        />
      ) : null}

      {loader === true ? (
        <div className="w-full p-[20px] flex justify-center items-center">
          {loaderSvg()}
        </div>
      ) : (
        <div
          className={
            "w-full flex flex-col h-full " +
            (taskDetailsContainerShow === true ? "opacity-50" : "opacity-100")
          }
        >
          {/* <div className="w-full h-[200px]   px-[52px] flex items-center  text-[20px] font-nunito font-medium bg-white">
            <div>Обзор задач</div>
          </div> */}
          <div className="bg-[#F2F3F5] w-full  p-[16px] flex flex-col gap-[16px] items-center text-[20px] font-nunito font-medium h-full">
            {/* Start Filter Container */}
            <div className="bg-white w-full  flex gap-[8px] items-center p-[16px] text-[20px]  font-nunito font-medium">
              {/* Filter Search */}
              <div className="px-[10px] p-[6px] flex gap-[5px] justify-center items-center border rounded-[6px]">
                <svg
                  width={16}
                  height={16}
                  className="fill-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                </svg>
                <input
                  className="text-[14px] font-nunito outline-none "
                  placeholder="Поиск по задачам"
                  onChange={handleSearchText}
                  value={searchText}
                />
              </div>

              {/* Refresh */}
              <div className="px-[10px] p-[6px] flex gap-[5px] justify-center items-center">
                {options?.length > 0 &&
                  options?.map((value: any, index: number) => {
                    if (value?.task_to_options?.length !== 0) {
                      return (
                        <select
                          onChange={(e) => setFilterOptions(e)}
                          key={index}
                          className="text-[14px] px-[10px] p-[6px] flex gap-[5px] justify-center items-center border rounded-[6px] font-nunito outline-none text-gray-500"
                        >
                          {value?.task_to_options?.length > 0 ? (
                            <option defaultValue={value?.name}>
                              {value?.name}
                            </option>
                          ) : null}
                          {value?.task_to_options?.length > 0 &&
                            value?.task_to_options?.map((v: any, i: number) => {
                              return (
                                <option
                                  key={i}
                                  data-option-id={value?.id}
                                  id={v?.id}
                                  value={v?.value}
                                >
                                  {v?.value}
                                </option>
                              );
                            })}
                        </select>
                      );
                    }
                  })}
              </div>

              <div className="flex justify-center items-center gap-[15px] px-[10px] p-[6px]">
                {parsedSelectedOptions instanceof Array &&
                  parsedSelectedOptions?.length > 0 &&
                  parsedSelectedOptions?.map((value: any, index: number) => {
                    return (
                      <div
                        className="flex justify-center items-center gap-[5px] cursor-pointer"
                        key={index}
                        onClick={() => removeOptionById(value?.id)}
                      >
                        <svg
                          width={12}
                          height={12}
                          className="fill-gray-300 hover:fill-gray-500 transition-all ease-linear"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                        </svg>
                        <div className="text-[14px] opacity-60 hover:opacity-100 transition-all ease-linear font-nunito cursor-pointer">
                          {value?.value}
                        </div>{" "}
                      </div>
                    );
                  })}
              </div>

              <div
                onClick={() => {
                  setParsedSelectedOptions([]);
                  setSearchText("");
                  //setStatus("В ожидании");
                  //searchParams.set("status", "В ожидании");
                  setSearchParams([]);

                  searchParams.set("user_id", userId);
                  searchParams.set("control_point_id", controlPointId);
                  searchParams.set("channel_work", channelWork);
                  searchParams.set("status", "В ожидании");

                  window.location.href = `/task?user_id=${userId}&control_point_id=${controlPointId}&channel_work=${channelWork}`;
                }}
                className="px-[10px] p-[6px] flex gap-[5px] justify-center items-center"
              >
                <svg
                  width={14}
                  height={14}
                  className="fill-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                </svg>
                <div className="text-[14px] opacity-60 font-nunito cursor-pointer">
                  Сбросить все
                </div>
              </div>
            </div>
            {/* End Filter Container */}
            <div className="w-full bg-white  flex flex-col justify-start items-start gap-[12px] p-[16px]   text-[20px] font-nunito font-medium">
              {/* Top filter button */}
              <div className="px-[10px] p-[6px] flex justify-center items-center bg-[#F8F8F8] rounded-[6px]">
                <button
                  onClick={() => {
                    setStatus("В ожидании");
                    searchParams.set("status", "В ожидании");
                    setSearchParams(searchParams);
                  }}
                  className={
                    "text-[14px] font-nunito cursor-pointer rounded-[2px] px-[14px] py-[6px] " +
                    (status == "В ожидании"
                      ? "bg-blue-100 text-black"
                      : "opacity-60")
                  }
                >
                  В ожидании
                </button>
                <button
                  onClick={() => {
                    setStatus("Незавершённые");
                    searchParams.set("status", "Незавершённые");
                    setSearchParams(searchParams);
                  }}
                  className={
                    "text-[14px]  font-nunito cursor-pointer rounded-[2px] px-[14px] py-[6px] " +
                    (status == "Незавершённые"
                      ? "bg-blue-100 text-black"
                      : "opacity-60")
                  }
                >
                  Незавершённые
                </button>
                <button
                  onClick={() => {
                    setStatus("Выполненные");
                    searchParams.set("status", "Выполненные");
                    setSearchParams(searchParams);
                  }}
                  className={
                    "text-[14px]  opacity-60 font-nunito cursor-pointer rounded-[2px] px-[14px] py-[6px] " +
                    (status == "Выполненные"
                      ? "bg-blue-100 text-black"
                      : "opacity-60")
                  }
                >
                  Выполненные
                </button>
              </div>

              {/* Table */}
              <table className="table-auto w-full border-collapse border border-slate-200">
                <thead className="bg-[#F2F3F5]">
                  <tr className="text-[14px] font-nunito font-medium text-left [&>*]:px-[16px] [&>*]:py-[17px]">
                    <th>ИД</th>
                    <th>Задача</th>
                    <th>Описание задачи</th>
                    {options?.length > 0 &&
                      options?.map((value: any, index: number) => {
                        return <th key={index}>{value?.name}</th>;
                      })}
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 &&
                    data.map((value: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          className={
                            "text-[14px] cursor-pointer hover:bg-[#F2F3F5] font-nunito font-normal text-left [&>*]:px-[16px] [&>*]:py-[17px] [&>*]:border-collapse [&>*]:border [&>*]:border-slate-200 " +
                            (value?.is_ready_to_start === true
                              ? "bg-[#dafeed]"
                              : "bg-[#F2F3F5]")
                          }
                        >
                          <td
                            onClick={() =>
                              navigate(
                                `/task/${value?.id}/${userId}/${controlPointId}/${channelWork}/continue`
                              )
                            }
                          >
                            {value?.id}
                          </td>
                          <td
                            onClick={() =>
                              navigate(
                                `/task/${value?.id}/${userId}/${controlPointId}/${channelWork}/continue`
                              )
                            }
                          >
                            {value?.operation?.name}
                          </td>
                          <td
                            onClick={() =>
                              navigate(
                                `/task/${value?.id}/${userId}/${controlPointId}/${channelWork}/continue`
                              )
                            }
                          >
                            {value?.operation?.description}
                          </td>
                          {options?.length > 0 &&
                            options?.map(
                              (voption: any, index_option: number) => {
                                return (
                                  <td key={index_option}>
                                    {value?.task_to_options?.length > 0 &&
                                      value?.task_to_options.map(
                                        (v: any, i: number) => {
                                          if (v?.option_id === voption?.id) {
                                            return (
                                              <div
                                                onClick={() =>
                                                  navigate(
                                                    `/task/${value?.id}/${userId}/${controlPointId}/${channelWork}/continue`
                                                  )
                                                }
                                                key={i}
                                                className="flex gap-[5px] justify-center items-center"
                                              >
                                                <div className="w-[6px] h-[6px] rounded-full bg-gray-300"></div>
                                                <span>{v?.value}</span>
                                              </div>
                                            );
                                          } else {
                                            return <td key={i}></td>;
                                          }
                                        }
                                      )}
                                  </td>
                                );
                              }
                            )}
                          {/* <td onClick={() => navigate("/task/1")}>
                            <div className="flex gap-[5px] justify-center items-center">
                              <div className="w-[6px] h-[6px] rounded-full bg-gray-300"></div>
                              <span>Критическая</span>
                            </div>
                          </td> */}
                          <td className="">
                            <div className="flex flex-col gap-[8px] min-w-[182px]">
                              <button
                                onClick={() => {
                                  setSelectedData(value);
                                  setTaskDetailsContainerShow(
                                    !taskDetailsContainerShow
                                  );
                                }}
                                className="leading-[20px] transition-all ease-linear hover:bg-[#4052ad] rounded-[2px] px-[15px] text-center text-[16px] font-medium bg-[#4E67EA] text-white font-nunito font-normal"
                              >
                                <div className="w-full h-full px-[15px] text-[16px] font-medium py-[14px] [&>svg]:hover:fill-[#FFFFFF]  transition-all ease-linear  flex justify-center items-center gap-[8px] hover:border-white hover:bg-[#4052ad] hover:text-white">
                                  <svg
                                    width={17}
                                    height={17}
                                    className="fill-white "
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                                  </svg>
                                  Подробнее о задаче
                                </div>
                              </button>
                              {status != "Выполненные" ? (
                                <button
                                  onClick={() =>
                                    navigate(
                                      `/task/${value?.id}/${userId}/${controlPointId}/${channelWork}/start`
                                    )
                                  }
                                  className="leading-[20px] rounded-[2px] text-center border border-[#4E67EA] text-[15px]  font-nunito font-normal"
                                >
                                  {value?.execution_time != null &&
                                  status != "Выполненные" ? (
                                    <div className="w-full h-full px-[15px] text-[16px] font-medium py-[12px] [&>svg]:hover:fill-[#FFFFFF]  transition-all ease-linear  flex justify-center items-center gap-[8px] hover:border-white hover:bg-[#4052ad] hover:text-white">
                                      <svg
                                        width={17}
                                        height={17}
                                        className="fill-[#4052ad] "
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                      >
                                        <path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z" />
                                      </svg>
                                      Продолжить выполнение
                                    </div>
                                  ) : (
                                    <div className="w-full flex px-[15px] text-[16px] font-medium py-[12px] hover:bg-[#4052ad] hover:text-white transition-all ease-linear   justify-center items-center gap-[8px] [&>svg]:hover:fill-[#FFFFFF]">
                                      <svg
                                        width={17}
                                        height={17}
                                        className="fill-[#4052ad] "
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 384 512"
                                      >
                                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                                      </svg>
                                      Начать выполнение
                                    </div>
                                  )}
                                </button>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="w-full flex justify-end">
              <div
                className="flex justify-center items-center gap-[10px] px-[10px]
            [&>span]:flex 
            [&>span]:justify-center
            [&>span]:items-center
            [&>span]:text-[14px] 
            [&>span]:w-[32px] 
            [&>span]:h-[32px]
            [&>span]:cursor-pointer 
           "
              >
                {totalPages > 1 ? (
                  <ProjectPagination
                    currentPage={currentPage}
                    totalItems={totalItems}
                    pageChange={handlePageChange}
                    itemsPerPage={limitFilter}
                  />
                ) : null}

                <div className="bg-blue-200 hover:bg-blue-300 px-[2px] py-[1px] rounded-[4px] text-center flex justify-center items-center cursor-pointer">
                  <Select
                    options={limitItemsOptions}
                    onChange={handleLimitSelect}
                    value={limitFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
