import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestGetFetch, requestPost } from "../../api/request";
import { USERS, SHIFT } from "../../api/url";
import { useAuth } from "../../useAuth";

export default function Modal({ modal, setModal, controlPointId }: any) {
  const { accessToken }: any = useAuth();
  const [users, setUsers] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  //Получаем пользователя с Бек
  const getUsers = async () => {
    const response = await requestGetFetch(
      USERS + searchParams.get("user_id") + "/",
      {},
      accessToken
    );

    if (response?.data?.length > 0) {
      setUsers(response?.data[0]);
      //Закрываем смену
      if (response?.data[0]?.shift.length > 0) {
        const response_shift = await requestPost(
          SHIFT + response?.data[0]?.shift[0]?.id + "/end/",
          {},
          {},
          accessToken
        );
      }
    }
  };

  const navigate = useNavigate();

  const exit = async () => {
    if (controlPointId != null && typeof controlPointId != "undefined") {
      await getUsers();
      setModal(!modal);
      navigate(`/login/?control_point_id=${controlPointId}`);
    }
  };

  return (
    <div className="absolute  left-0 right-0 top-0 z-50 flex h-[calc(100%-1rem)] max-h-full w-full items-center justify-center  overflow-y-auto  overflow-x-hidden text-center ">
      <div className="relative max-h-full w-full max-w-[591px] p-4">
        <div className="relative rounded-[8px] bg-white shadow flex flex-col gap-[20px] ">
          <div className="w-full flex justify-end items-end px-[12px]">
            <button
              onClick={() => setModal(!modal)}
              type="button"
              className="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
          <div className="w-full px-[32px]">
            <div className="w-full flex flex-col gap-[24px]">
              <div className="w-full flex flex-col gap-[5px] justify-start items-start">
                <h3 className="text-[18px] font-medium font-nunito  text-[#252628] ">
                  {/* {selectedData?.operation?.name} */}
                </h3>
                <p className="font-nunito text-[14px] text-start">
                  У вас остались незавершенные задачи. Вы уверены, что хотите
                  завершить смену?
                </p>
              </div>
            </div>
          </div>
          <div className="w-full border-t-[1px] border-[#cfcfcf] flex ">
            <div
              onClick={() => {
                setModal(!modal);
              }}
              className="py-[13px] cursor-pointer hover:bg-blue-100 active:bg-blue-200 w-1/2 flex flex-col justify-center items-center"
            >
              <p className="font-nunito text-[14px] ">Отменить</p>
            </div>
            <div
              onClick={() => {
                exit();
              }}
              className="select-none py-[13px] w-1/2 bg-[#4E67EA] active:bg-[#5870ed] hover:bg-[#768aed] flex flex-col justify-center items-center cursor-pointer"
            >
              <p className="select-none font-nunito text-[14px] text-white">
                Завершить смену
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
