export default function TaskDetailsBlueprintsModal() {
  return (
    <div className="w-full overflow-y-auto">
      <div className="w-full flex flex-col gap-[20px]">
        <div className="w-full bg-[#C4C4C4] h-[272px]"></div>
        <div className="w-full bg-[#C4C4C4] h-[40px]"></div>
      </div>
    </div>
  );
}
