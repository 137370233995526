import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestGetFetch } from "../../api/request";
import { USERS } from "../../api/url";
import loginIcon from "../../assets/images/login_icon.png";
import { useAuth } from "../../useAuth";

export default function WelcomeUser() {
  const { accessToken }: any = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [controlPointId, setControlPointId] = useState(
    searchParams.get("control_point_id") || undefined
  );

  const [channelWork, setChannelWork] = useState(
    searchParams.get("channel_work") || undefined
  );

  const [userId, setUserId] = useState(
    searchParams.get("user_id") || undefined
  );

  const getData = async () => {
    const response = await requestGetFetch(
      USERS + userId + "/",
      {},
      accessToken
    );
    if (response?.data?.length > 0) {
      setData(response?.data);
      setSelectedData(response?.data[0]);
    }
  };

  const startTask = () => {
    if (
      typeof userId != "undefined" &&
      typeof controlPointId != "undefined" &&
      selectedData != null
    ) {
      setTimeout(() => {
        navigate(
          `/task?user_id=${userId}&control_point_id=${controlPointId}&channel_work=${channelWork}`
        );
      }, 4000);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    startTask();
  }, [selectedData]);

  useEffect(() => {
    document.title = "ПРОметрика";
  }, []);

  return (
    <div className="w-full  flex justify-center items-center py-[195px] h-full bg-[#F8F8F8]">
      <div className="flex flex-col justify-center items-center gap-[24px] w-[536px] bg-white p-[52px] rounded-[8px]">
        <div className="w-[80px] h-[80px] ">
          <img className="" src={loginIcon} />
        </div>
        {selectedData != null ? (
          <>
            <div className="text-[22px] font-nunito font-semibold text-center w-full px-[35px]">
              Хорошего рабочего дня, {selectedData?.name}!
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
